<template>
  <div class="q-pa-md">
    <Mural
      :notificationQuantity="listUsers.length"
      :to="{ name: 'new-user' }"
      route="home"
      :query="{}"
      :breadcrumbs="breadcrumbs"
    >
      <div>
        <DefaultBtn
          v-show="validateActionsPage('users-add')"
          titleButton="Criar usuário"
          icon="add"
          route="new-user"
        />
        <q-btn
          color="default-pink"
          rounded
          no-caps
          unelevated
          :query="{}"
          name=""
          class="q-ml-md"
          to="/user-batch"
          v-show="validateActionsPage('users-add')"
        >
          <span>
            <q-icon size="xs" name="publish" />
          </span>
          <p style="margin: 0px 10px">Cadastro em Lote</p>
        </q-btn>
      </div>
    </Mural>
    <main class="q-ma-md main-content">
      <div class="q-mb-md">
        <q-btn
          color="default-pink"
          label="Inativação de usuários"
          no-caps
          @click="isVisible = !isVisible"
        />
      </div>
      <div class="select-inputs" v-if="isVisible">
        <!--<div>
          <label for="leader">Líder</label>
          <q-select
            dense
            outlined
            color="default-pink"
            bg-color="grey-3"
            class="col-11"
            id="semgments"
            :multiple="false"
            use-input
            emit-value
            map-options
            @filter="filterFnLeaders"
            v-model="users.leaders"
            :options="optionListLeaders"
            ><template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div> -->
        <div class="q-mb-md">
          <div class="row items-center">
            <label class="q-mr-sm">Segmentos</label>
            <q-img
              src="@/assets/icons/info_cicle.svg"
              width="16px"
              height="16px"
            />
            <q-tooltip anchor="top left" self="top left">
              Utilize os segmentos para poder filtrar os grupos
            </q-tooltip>
          </div>
          <q-select
            dense
            outlined
            color="default-pink"
            bg-color="grey-3"
            class="col-11"
            id="semgments"
            multiple
            use-chips
            use-input
            emit-value
            map-options
            @filter="filterFnBranches"
            v-model="users.branches"
            :options="listBranches"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <div class="container-groups q-mb-md">
          <div class="item-groups">
            <label for="">Grupos</label>
            <q-select
              dense
              outlined
              color="default-pink"
              bg-color="grey-3"
              class="col-11"
              multiple
              use-input
              use-chips
              emit-value
              @filter="filterFnGroups"
              map-options
              v-model="users.groups"
              :options="optionsGroups"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <q-checkbox
            left-label
            v-model="allGroups"
            label="Selecionar todos"
            color="default-pink"
            @update:model-value="selectedFilteredGroups"
          />
        </div>
        <div class="radio-inputs q-mt-md">
          <label>Período sem acessar</label>
          <div class="q-gutter-xl row wrap">
            <div v-for="(item, index) in period" :key="index">
              <q-radio
                v-model="users.days"
                :val="item.value"
                :label="item.label"
                color="default-pink"
              />
            </div>
          </div>
        </div>
        <div class="self-end">
          <q-btn
            v-if="isFiltering"
            color="default-pink"
            flat
            @click="resetFilters"
            no-caps
            class="q-mr-sm"
          >
            <label for="" class="cursor-pointer">Limpar</label>
          </q-btn>
          <q-btn
            color="default-pink"
            :disable="canUserFilter"
            :outline="true"
            no-caps
            @click="filterUsers"
          >
            <label for="" class="cursor-pointer">Filtrar</label>
          </q-btn>
        </div>
      </div>
      <div class="content-table row flex">
        <div class="column col-12 q-pt-lg">
          <q-table
            color="default-pink"
            :selection="isVisible ? 'multiple' : 'none'"
            v-model:selected="selected"
            class="no-shadow"
            :rows="listUsers"
            :columns="isMaster ? columnsTableForMaster : columnsTable"
            row-key="id"
            :filter="filter"
          >
            <template v-slot:top-left>
              <q-btn
                unelevated
                rounded
                color="default-pink"
                label="Inativar"
                size="md"
                no-caps
                icon-right="delete"
                outline
                v-if="
                  selected.length > 0 &&
                  validateActionsPage('users-delete') &&
                  isVisible
                "
                @click="disabled(selected)"
              />
            </template>
            <template v-slot:top-right>
              <q-btn
                flat
                rounded
                color="default-pink"
                size="12px"
                class="q-mr-sm"
              >
                <q-img width="20px" :src="filterImg" spinner-color="white" />
                <q-menu>
                  <q-list style="min-width: 100px">
                    <q-item clickable v-close-popup>
                      <q-item-section @click="handleFilterlistUers(0)">
                        <p>Inativos</p>
                      </q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      @click="handleFilterlistUers(1)"
                    >
                      <q-item-section>
                        <p>Ativos</p>
                      </q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      @click="handleFilterlistUers(2)"
                    >
                      <q-item-section>
                        <p>Todos</p>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
              <q-input
                dense
                color="default-pink"
                :outlined="true"
                rounded
                v-model="filter"
                placeholder="Pesquisar"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
            <template v-slot:body-cell-name_group="props">
              <q-td :props="props">
                <div class="name_groups">{{ props.row.name_group }}</div>
              </q-td>
            </template>
            <template v-slot:body-cell-acao="props">
              <q-td :props="props">
                <div>
                  <q-btn
                    v-if="
                      validateActionsPage('users-edit') ||
                      validateActionsPage('users-reset-password')
                    "
                    dense
                    round
                    color="default-pink"
                    title="Editar"
                    flat
                    @click="
                      () => {
                        router.push({
                          name: 'new-user',
                          query: { id: props.row.id },
                        });
                      }
                    "
                    icon="o_edit_note"
                  />
                  <q-btn
                    v-if="validateActionsPage('add-points-manual')"
                    dense
                    round
                    color="default-pink"
                    title="Editar"
                    flat
                    @click="() => selectedUser(props.row)"
                  >
                    <coinsImg color="var(--q-secundary)" />
                    <!-- <q-img
                    :src="coins"
                    class="button-icon q-mx-sm"
                    color="white"
                    width="20px"
                    height="auto"
                  /> -->
                  </q-btn>
                </div>
              </q-td>
            </template>
          </q-table>
        </div>
      </div>
    </main>
    <DialogPoints
      v-if="showGroup"
      :showDialog="showGroup"
      :group="userSelected"
      :userImage="usersImg"
      :isUser="true"
      @closeDialog="() => (showGroup = false)"
      @saveDialog="(value) => addCoinsUser(value)"
    />

    <q-inner-loading
      :showing="loadingPage"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <div class="collumn justify-center q-my-md">
        <div style="height: auto; width: 50px; margin: auto">
          <LoadingIcon />
        </div>
        <label>Por favor aguarde...</label>
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
//----IMAGES
import usersImg from "@/assets/icons/menu/dark_icon/users.svg";
// import coins from "@/assets/icons/coins.svg";
import filterImg from "@/assets/icons/filter.svg";
import coinsImg from "@/assets/icons/custom/Coins.vue";

//----COMPONENTS
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import DialogPoints from "@/components/admin/dialogs/DialogPoints.vue";
import SelectWithSearch from "@/components/reports/inputs/SelectWithSearch.vue";
import TitleButton from "@/components/reports/buttons/titleButton.vue";

import CanUserAccessClass from "@/utils/CanUserAccessClass";

//----SERVICE
import UserServices from "@/services/UserServices";
import PointServices from "@/services/PointServices";
import GroupServices from "@/services/GroupServices";

//----Utils
import { ENV } from "@/utils/env";

//----QUASAR
import { useQuasar } from "quasar";

//----VUEJS
import { ref, onMounted, reactive, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "User",
  components: {
    Mural,
    DefaultBtn,
    LoadingIcon,
    DialogPoints,
    coinsImg,
    SelectWithSearch,
    TitleButton,
  },
  setup() {
    //-----SERVICES
    const userServices = new UserServices();
    const pointServices = new PointServices();
    const _GroupServices = new GroupServices();

    //-----CONSTANTS
    const router = new useRouter();
    const store = new useStore();
    const breadcrumbs = ref(["Usuários"]);
    const $q = new useQuasar();
    const users = reactive({
      groups: [],
      branches: [],
      leaders: null,
      days: null,
    });
    const period = ref([
      {
        label: "Há 7 dias",
        value: 1,
      },
      {
        label: "Há 15 dias",
        value: 4,
      },
      {
        label: "Há 30 dias",
        value: 2,
      },
      {
        label: "Mais de 30 dias",
        value: 3,
      },
      {
        label: "Qualquer período",
        value: 5,
      },
    ]);
    const columnsTable = ref([
      { name: "id", align: "left", label: "ID", field: "id", sortable: false },
      {
        name: "name",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.first_name + " " + row.last_name,
        sortable: true,
      },
      {
        name: "email",
        align: "left",
        label: "Email",
        field: "email",
        sortable: false,
      },
      {
        name: "days_without_login",
        align: "left",
        label: "Dias sem acessar",
        field: (row) =>
          row.days_without_login != 1
            ? `${row.days_without_login} dias`
            : `${row.days_without_login} dia`,
        sortable: false,
      },
      {
        name: "name_group",
        align: "left",
        label: "Grupo",
        field: "name_group",
        sortable: false,
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
        sortable: false,
      },
    ]);
    const columnsTableForMaster = ref([
      { name: "id", align: "left", label: "ID", field: "id", sortable: false },
      {
        name: "name",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.first_name + " " + row.last_name,
        sortable: true,
      },
      {
        name: "email",
        align: "left",
        label: "Email",
        field: "email",
        sortable: false,
      },
      {
        name: "company_name",
        align: "left",
        label: "Empresa",
        field: "company_name",
        sortable: false,
      },
      {
        name: "days_without_login",
        align: "left",
        label: "Dias sem acessar",
        field: (row) =>
          row.days_without_login != 1
            ? `${row.days_without_login} dias`
            : `${row.days_without_login} dia`,
        sortable: false,
      },
      {
        name: "name_group",
        align: "left",
        label: "Grupo",
        field: "name_group",
        sortable: false,
      },
      /*       {
        name: "name_alias",
        align: "left",
        label: "Filial",
        field: "name_alias",
        sortable: false,
      }, */
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
        sortable: false,
      },
    ]);
    const canUserAccessClass = new CanUserAccessClass();
    const filter = ref("");

    //-----VARIABLES
    let isFiltering = ref(false);
    let isVisible = ref(false);
    let listUsers = ref([]);
    let listLeaders = ref([]);
    let optionListLeaders = ref([]);
    let loadingPage = ref(false);
    let userSelected = ref({});
    let showGroup = ref(false);
    let listGroups = ref([]);
    let optionsGroups = ref([]);
    let listBranches = ref([]);
    let optionListBranches = ref([]);
    let allGroups = ref(false);
    let selected = ref([]);

    onMounted(() => {
      _listUsers();
      getBranches();
      getAllGroups();
      /*   getAllLeaders(); */
    });

    const isMaster = store.state.userdata.master == 1;

    const canUserFilter = computed(() => {
      return users.groups.length > 0 || users.days != null ? false : true;
    });

    watch(
      () => users.branches,
      (newBranch, oldBranch) => {
        if (newBranch.length == 0) {
          users.groups = null;
          users.groups = [];
          allGroups.value = false;
        }

        if (newBranch.length != oldBranch.length) {
          optionsGroups.value = listGroups.value;
        }

        if (newBranch.length != 0) {
          optionsGroups.value = listGroups.value.filter((group) => {
            return newBranch.includes(group.branch_id);
          });

          let groupValues = optionsGroups.value.map((group) => {
            return group.value;
          });

          users.groups = groupValues.filter((value) => {
            if (users.groups.includes(value)) {
              return value;
            }
          });
        }
      }
    );

    watch(optionsGroups, (newGroup, oldGroup) => {
      if (allGroups.value && users.branches.length != 0) {
        if (newGroup.length > oldGroup.length) {
          optionsGroups.value.filter((group) => {
            if (!users.groups.includes(group.value)) {
              users.groups.push(group.value);
            }
          });
        }
      }
    });

    function filterUsers() {
      const SEVEN_DAYS_AGO = 7;
      const FIFTEEN_DAYS_AGO = 15;
      const THIRTY_DAYS_AGO = 30;
      const MORE_THAN_THIRTY_DAYS = 30;

      isFiltering.value = true;

      const filteredUsers = listUsers.value.filter((user) => {
        const { lider, group_id, days_without_login } = user;

        if (users.groups.length > 0 && users.days != null) {
          if (
            users.groups.includes(...group_id) &&
            users.days === 1 &&
            SEVEN_DAYS_AGO == days_without_login
          ) {
            return user;
          }

          if (
            users.groups.includes(...group_id) &&
            users.days === 4 &&
            FIFTEEN_DAYS_AGO == days_without_login
          ) {
            return user;
          }

          if (
            users.groups.includes(...group_id) &&
            users.days === 2 &&
            THIRTY_DAYS_AGO == days_without_login
          ) {
            return user;
          }

          if (
            users.groups.includes(...group_id) &&
            users.days === 3 &&
            days_without_login > MORE_THAN_THIRTY_DAYS
          ) {
            return user;
          }
          if (users.groups.includes(...group_id) && users.days === 5) {
            return user;
          }
        } else if (users.groups.length > 0) {
          if (users.groups.includes(...group_id)) {
            return user;
          }
        } else {
          if (users.days === 1 && SEVEN_DAYS_AGO == days_without_login) {
            return user;
          }

          if (users.days === 2 && THIRTY_DAYS_AGO == days_without_login) {
            return user;
          }

          if (users.days === 3 && days_without_login > MORE_THAN_THIRTY_DAYS) {
            return user;
          }

          if (users.days === 4 && FIFTEEN_DAYS_AGO == days_without_login) {
            return user;
          }
          if (users.days === 5) {
            return user;
          }
        }
      });

      listUsers.value = filteredUsers;
    }

    function getAllLeaders() {
      userServices
        .getAllLeaders()
        .then((response) => {
          response.data.map((item) => {
            listLeaders.value.push({
              label: item.first_name + " " + item.last_name,
              value: item.id,
            });
          });

          optionListLeaders.value = listLeaders.value;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getAllGroups() {
      _GroupServices
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
              branch_id: item.branch_id,
            });
          });
          optionsGroups.value = listGroups.value;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getBranches() {
      await _GroupServices
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function filterFnLeaders(val, update) {
      if (val === "") {
        update(() => {
          optionListLeaders.value = listLeaders.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        optionListLeaders.value = listLeaders.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          if (users.branches.length > 0) {
            optionsGroups.value = listGroups.value.filter((group) => {
              return users.branches.includes(group.branch_id);
            });
          }
          optionsGroups.value = listGroups.value;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsGroups.value = optionsGroups.value.filter((v) => {
          if (users.branches.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              users.branches.includes(v.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }

    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    async function _listUsers() {
      loadingPage.value = true;
      userServices.getAllUser().then((response) => {
        listUsers.value = response.data;
        loadingPage.value = false;
      });
    }

    async function listUserInactiveByGroup() {
      loadingPage.value = true;
      userServices.getAllUserInactive().then((response) => {
        listUsers.value = response.data;
        loadingPage.value = false;
      });
    }

    async function listAllUser() {
      loadingPage.value = true;
      let listUserActive = ref([]);

      userServices.getAllUser().then((response) => {
        listUserActive.value.push(...response.data);
      });

      userServices.getAllUserInactive().then((response) => {
        listUserActive.value.push(...response.data);
      });
      loadingPage.value = false;

      listUsers.value = listUserActive.value;
    }

    function resetFilters() {
      isFiltering.value = false;
      users.branches = [];
      users.groups = [];
      users.days = null;
      listUsers.value = [];
      _listUsers();
    }

    async function handleFilterlistUers(value) {
      const USER_INACTIVE = 0;
      const USER_ACTIVE = 1;
      const ALL_USERS = 2;

      switch (value) {
        case USER_INACTIVE:
          await listUserInactiveByGroup();
          return;

        case USER_ACTIVE:
          await _listUsers();
          return;

        case ALL_USERS:
          listAllUser();
          return;
        default:
          await _listUsers();
          return;
      }
    }

    async function disabled(users) {
      let message =
        users.length > 1
          ? "Deseja remover os grupos selecionados?"
          : "Deseja remover o grupo selecionado?";
      $q.notify({
        message: message,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              await disableUsers(users);
            },
          },
        ],
      });
    }

    async function disableUsers(users) {
      loadingPage.value = true;
      const formData = new FormData();

      users.forEach((user) => {
        const { id } = user;
        formData.append("Usersid", id);
      });

      userServices
        .disableUsers(formData)
        .then((response) => {
          _alertOf(`Usuários desativados com sucesso!`, "green-9");
          listUsers.value = [];
          selected.value = [];
          _listUsers();
          loadingPage.value = false;
        })
        .catch((error) => {
          _alertOf(error.response.data.toString());
          loadingPage.value = false;
        });
    }

    function selectedFilteredGroups() {
      if (allGroups.value) {
        optionsGroups.value.filter((group) => {
          if (!users.groups.includes(group.value)) {
            users.groups.push(group.value);
          }
        });
      } else {
        users.groups = null;
        users.groups = [];
      }
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    async function selectedUser(user) {
      userSelected.value = {
        group_name: user.first_name,
        id: user.id,
        image: ENV.URL_AWS_BUCKET + user.avatar_location,
      };
      showGroup.value = true;
    }

    async function addCoinsUser(user) {
      let EVENT_ID = 7;
      let EVENT_TYPE = "C";
      let TYPE_ID = "user";

      let formDataAddPoint = new FormData();

      formDataAddPoint.append("EventId", EVENT_ID);
      formDataAddPoint.append("EventType", EVENT_TYPE);
      formDataAddPoint.append("IdType", TYPE_ID);
      formDataAddPoint.append("UserId", user.group);
      formDataAddPoint.append("Value", user.value);
      formDataAddPoint.append("Observation", user.description);

      await pointServices
        .addPoint(formDataAddPoint)
        .then(() => {
          _notify("Moedas adicionado com sucesso", "green-9");
        })
        .catch((err) => {
          _notify("Erro ao adicionar moedas");
        });
      showGroup.value = false;
    }

    function _notify(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    return {
      //----IMAGES
      // coins,
      usersImg,
      filterImg,

      //----CONSTANTS
      router,
      breadcrumbs,
      columnsTable,
      columnsTableForMaster,
      users,
      filter,
      period,

      //----VARIABLES
      listUsers,
      loadingPage,
      showGroup,
      userSelected,
      listBranches,
      optionsGroups,
      listLeaders,
      allGroups,
      isVisible,
      selected,
      optionListLeaders,
      isFiltering,

      // LIFECYCLES
      canUserFilter,
      isMaster,

      //----FUNCTIONS
      disabled,
      selectedUser,
      handleFilterlistUers,
      addCoinsUser,
      validateActionsPage,
      filterFnGroups,
      filterFnBranches,
      selectedFilteredGroups,
      filterUsers,
      filterFnLeaders,
      resetFilters,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  height: 95vh;
  width: 100%;
}
.content-table {
  height: 80%;
  width: 100%;
}

.select-inputs {
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
}

.container-groups {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.icon-btn {
  filter: brightness(2.5);
}

.item-groups {
  width: calc(80% - 25px);
  margin-right: 25px;
}

.radio-inputs h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #9c9c9c;
  margin-bottom: 1.125rem;
}

.name_groups {
  overflow: scroll;
  max-width: 250px;
}
</style>
